const firebaseConfig = {
  projectId: "oxford-rise-cneig",
  appId: "1:851588590347:web:fb3c9591997017b5d3900e",
  databaseURL:
    "https://oxford-rise-cneig-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "oxford-rise-cneig.firebasestorage.app",
  apiKey: "AIzaSyBpCMl4g5tVfDBYQQ8MvPdieYfabHNCA9c",
  authDomain: "oxford-rise-cneig.firebaseapp.com",
  messagingSenderId: "851588590347",
};

export default firebaseConfig;
